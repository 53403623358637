import { ReactComponent as TwitterIcon } from 'img/newIndex/footer/socials/twitter.svg'
// import { ReactComponent as InstagramIcon } from 'img/newIndex/footer/socials/instagramm.svg'
import { ReactComponent as RedditIcon } from 'img/newIndex/footer/socials/reddit.svg'
import { ReactComponent as TelegramIcon } from 'img/newIndex/footer/socials/telegram.svg'
import { ReactComponent as MediumIcon } from 'img/newIndex/footer/socials/medium.svg'
import { ReactComponent as DiscordIcon } from 'img/newIndex/footer/socials/discord.svg'

export default [
  {
    name: 'Twitter',
    link: 'https://twitter.com/OccamFi',
    getIcon: (fill: string | undefined) => <TwitterIcon fill={fill} />,
  },
  // {
  //   name: 'Instagram',
  //   link: 'https://www.instagram.com/occamfi',
  //   getIcon: (fill: string | undefined) => <InstagramIcon fill={fill} />,
  // },
  {
    name: 'Reddit',
    link: 'https://www.reddit.com/user/OccamFi',
    getIcon: (fill: string | undefined) => <RedditIcon fill={fill} />,
  },
  {
    name: 'Telegram',
    link: 'https://t.me/occamfi_com',
    getIcon: (fill: string | undefined) => <TelegramIcon fill={fill} />,
  },
  {
    name: 'Medium',
    link: 'https://medium.com/occam-finance',
    getIcon: (fill: string | undefined) => <MediumIcon fill={fill} />,
  },
  {
    name: 'Discord',
    link: 'https://discord.com/invite/9eQbAkPj7K',
    getIcon: (fill: string | undefined) => <DiscordIcon fill={fill} />,
  },
]
